import { observer } from 'mobx-react-lite';
import { CardContent } from '@mui/material';
import FormGrid from '~/components/@material-extend/form_grid';
import I18n from '~/utils/i18n';
import useLocales from '~/hooks/use_locales';
import Select from '~/components/final_form/select';
import FormContainer from '~/pages/device_edit/containers/form_container';
import DownloadDeviceCertificateButton from '~/components/download_device_certificate_button';
import { ISyncable } from '~/mst/models/abstract/syncable';

type AdminSettingsFormType = {
  handleSubmit: VoidFunction;
  model: ISyncable;
};

const ActionsOptions = [
  { value: 'reboot', label: 'Reboot' },
  { value: 'format', label: 'Format' },
  { value: 'sim_reset', label: 'SIM Reset' }
];

const EndpointOptions = [{ value: 'default', label: I18n.t('attributes.thiamis.settings_endpoint_default') }];

function AdminSettingsForm({ handleSubmit, model }: AdminSettingsFormType) {
  const { t } = useLocales();
  return (
    <FormContainer title={t('device_monitor.forms.admin')} handleSubmit={handleSubmit} model={model}>
      <CardContent>
        <FormGrid>
          <Select searchable name="endpoint" label={t('attributes.thiamis.settings_endpoint')} options={EndpointOptions} autoSelect freeSolo />
          <Select name="action" label={t('attributes.thiamis.settings_run_action')} options={ActionsOptions} placeholder="Choose action..." />
          <DownloadDeviceCertificateButton node={model} />
        </FormGrid>
      </CardContent>
    </FormContainer>
  );
}

export default observer(AdminSettingsForm);
