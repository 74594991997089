import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import CreatedAtFormatter from '~/components/react_grid/time_ago_formatter';
import DatetimeFormatter from '~/components/react_grid/datetime_formatter';
import ActionsFormatter from './actions_formatter';
import NameFormatter from './name_formatter';

export function CreatedAtProvider(props: DataTypeProviderProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider formatterComponent={CreatedAtFormatter} {...props} />;
}

export function DatetimeProvider(props: DataTypeProviderProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider formatterComponent={DatetimeFormatter} {...props} />;
}

export function ActionsProvider(props: DataTypeProviderProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider {...props} formatterComponent={ActionsFormatter} />;
}

export function NameProvider(props: DataTypeProviderProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider {...props} formatterComponent={NameFormatter} />;
}
