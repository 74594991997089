import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { observer } from 'mobx-react-lite';
import { Tooltip, Grid } from '@mui/material';
import { IconButtonAnimate } from '~/components/animate';
import Select from '~/components/final_form/select';
import useLocales from '~/hooks/use_locales';
import { CircleRemoveStyled } from '~/pages/alert_edit/conditions_block/styled';
import TextInput from '~/components/final_form/text_input';

export type ConditionType = {
  func: string;
  device: string;
  dataPoint: string;
  value: string | number;
};

type DataPointFieldType = FieldArrayRenderProps<ConditionType, HTMLInputElement> & {
  name: string;
  index: number;
};

function DataPointField(props: DataPointFieldType) {
  const { name, options, isLoading, fields, index } = props;
  const { t } = useLocales();
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={6}>
        <Select searchable name={`${name}.name`} label={t('models.parameter')} options={options} loading={isLoading} />
      </Grid>
      <Grid item md={2}>
        <TextInput noHelperText name={`${name}.value`} data-testid={`${name}.valueInput`} label="Alert Level" />
      </Grid>
      <Grid item md={1}>
        <Tooltip title="Remove Parameter">
          <IconButtonAnimate data-testid={`removeParameterBtn${index}`} onClick={() => fields.remove(index)}>
            <CircleRemoveStyled />
          </IconButtonAnimate>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default observer(DataPointField);
