import { SortingState, IntegratedSorting, IntegratedPaging, PagingState, FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, PagingPanel, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { observer } from 'mobx-react-lite';
import saveAs from 'file-saver';
import { DateTime } from 'luxon';

import { formatDateFromTs } from '~/utils/format_date';
import useLoading from '~/hooks/react_grid/use_loading';
import useSorting from '~/hooks/react_grid/use_sorting';
import usePaging from '~/hooks/react_grid/use_paging';
import { Container, Row } from '~/components/react_grid';
import { useEffect, useRef } from 'react';
import useExport from '~/hooks/react_grid/use_export';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { GridStyledNoWrap } from './styled';
import { IDeviceAdmin } from '../../../store';

import { DateTimeProvider } from './info_providers';

const getRowId = ({ ts }) => ts;
function compareDates(a: number, b: number) {
  // @ts-ignore
  return (b != null) - (a != null) || b - a;
}

export const columns = [
  { name: 'ts', title: 'Timestamp' },
  { name: 'message', title: 'Message' },
  { name: 'tag', title: 'Tag' }
];

function FilterIcon(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TableFilterRow.Icon {...props} />;
}

const columnExtensions = [{ columnName: 'message', width: 450, wordWrapEnabled: false }];
const columnExtensionsSorting = [{ columnName: 'ts', compare: compareDates }];
const customizeCell = (cell, { ts }, column) => {
  if (column.name === 'ts') {
    // eslint-disable-next-line no-param-reassign
    cell.value = formatDateFromTs(ts, { format: DateTime.DATETIME_SHORT_WITH_SECONDS });
  }
};
function DebugTable({ report }: { report: IDeviceAdmin }) {
  const { sorting, handleSortingChange } = useSorting([{ columnName: 'ts', direction: 'asc' }]);
  const { messages, RootComponent } = useLoading(report?.debugWidget);
  const { pageSizes, pageSize, handlePageSizeChange } = usePaging();

  const exporterRef = useRef(null);
  const { isExporting, stopExport } = useExport();

  useEffect(() => {
    if (isExporting) {
      stopExport();
      exporterRef.current?.exportGrid();
    }
  }, [isExporting, exporterRef, stopExport]);

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'devices.xlsx');
    });
  };

  const rows = report.debugWidget.filteredList;

  return (
    <GridStyledNoWrap>
      <Grid rows={rows} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting columnExtensions={columnExtensionsSorting} />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} pageSize={pageSize} onPageSizeChange={handlePageSizeChange} />
        <IntegratedPaging />
        <DateTimeProvider for={['ts']} />
        <Table rowComponent={Row} containerComponent={Container} messages={messages} height="auto" columnExtensions={columnExtensions} />
        <TableHeaderRow showSortingControls />
        <TableFilterRow showFilterSelector iconComponent={FilterIcon} />
        {report.debugWidget.filteredList.length > pageSize && <PagingPanel pageSizes={pageSizes} />}
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
        customizeCell={customizeCell}
        sorting={sorting}
        getRowId={getRowId}
      />
    </GridStyledNoWrap>
  );
}

export default observer(DebugTable);
