/* eslint-disable react/jsx-props-no-spreading */
import { TextField, Box, FormHelperText, FormControl } from '@mui/material';
import { DateTime } from 'luxon';
import isNumber from 'lodash/isNumber';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Field as FinalField, FieldMetaState } from 'react-final-form';
import { SxProps } from '@mui/system';

interface RFFDateTimePickerProps {
  name: string;
  label: string;
  validate?: (value?: any, allValues?: Object, meta?: FieldMetaState<any>) => any; // eslint-disable-line react/require-default-props
  sx?: SxProps; // eslint-disable-line react/require-default-props
}

export default function RFFDateTimePicker(props: RFFDateTimePickerProps) {
  const { name, sx, validate, ...rest } = props;
  return (
    <FinalField
      name={name}
      validate={validate}
      parse={(value) => {
        if (DateTime.isDateTime(value)) {
          return value.toUTC().toMillis();
        }
        return value;
      }}
      format={(value) => {
        if (isNumber(value) && DateTime.fromMillis(value)?.isValid) {
          return DateTime.fromMillis(value);
        }
        return value;
      }}
    >
      {({ input, meta }) => {
        const hasError = meta.touched && meta.error;
        return (
          <Box sx={sx}>
            <FormControl error={Boolean(hasError)} fullWidth>
              <DateTimePicker
                {...rest}
                ampmInClock={false}
                ampm={false}
                value={input.value}
                onChange={(value) => {
                  if (DateTime.isDateTime(value) && value.isValid) {
                    input.onChange(value.toUTC().toMillis());
                  }
                }}
                renderInput={(inputProps) => <TextField {...inputProps} {...input} error={Boolean(hasError)} />}
              />
              <FormHelperText>{hasError}</FormHelperText>
            </FormControl>
          </Box>
        );
      }}
    </FinalField>
  );
}
