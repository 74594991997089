import { DateTime } from 'luxon';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import { formatDateFromTs, formatDateFromIso } from '~/utils/format_date';

function DateTimeFormatter({ value, row, column }: { value: number; row: any; column: any }) {
  if (value && isString(value) && DateTime.fromISO(value)?.isValid) {
    if (row.timezone) {
      return formatDateFromIso(value, { timezone: row.timezone, format: column.format });
    }
    return formatDateFromIso(value, { format: column.format });
  }
  if (value && isNumber(value) && DateTime.fromMillis(value)?.isValid) {
    if (row.timezone) {
      return formatDateFromTs(value, { timezone: row.timezone, format: column.format });
    }
    return formatDateFromTs(value, { format: column.format });
  }
}

export default DateTimeFormatter;
