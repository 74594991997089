/* eslint-disable import/prefer-default-export */
import { DateTime } from 'luxon';

export function formatDateFromTs(ms: number, options: { format?: any; timezone?: string } = {}): string {
  const { format = DateTime.DATETIME_SHORT, timezone } = options;
  if (timezone) {
    return DateTime.fromMillis(ms).setZone(timezone).toLocaleString(format);
  }
  return DateTime.fromMillis(ms).toLocaleString(format);
}

export function formatDateFromIso(date: string, options: { format?: any; timezone?: string } = {}): string {
  const { format = DateTime.DATETIME_SHORT, timezone } = options;
  if (timezone) {
    return DateTime.fromISO(date).setZone(timezone).toLocaleString(format);
  }
  return DateTime.fromISO(date).toLocaleString(format);
}
