/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { downloadDeviceCertificate } from '~/api/rest/nodes';
import toasts from '~/utils/toasts';

import { LoadingButton } from '@mui/lab';
import useLocales from '~/hooks/use_locales';
import type { INode } from '~/mst/models/node';

function DownloadDeviceCertificateButton({ node }: { node: INode }) {
  const { t } = useLocales();
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <LoadingButton
      size="small"
      loading={isDownloading}
      onClick={async () => {
        try {
          setIsDownloading(true);
          const { data } = await downloadDeviceCertificate(node?.serial);
          saveAs(data, `${node?.serial}.crt`);
          setIsDownloading(false);
        } catch (e) {
          toasts.error(t('notifications.errors.server_error'));
          setIsDownloading(false);
        }
      }}
    >
      Download Device Certificate
    </LoadingButton>
  );
}

export default DownloadDeviceCertificateButton;
