import React, { useEffect, useMemo } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useFormState, useForm } from 'react-final-form';
import { observable, action } from 'mobx';
import { OnChange } from 'react-final-form-listeners';
import { FieldArray } from 'react-final-form-arrays';
import isString from 'lodash/isString';
import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty';
import flow from 'lodash/fp/flow';
import first from 'lodash/first';
import map from 'lodash/fp/map';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import Switch from '~/components/final_form/switch';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import OrganizationSelect from '~/components/organizations_select';
import useLocales from '~/hooks/use_locales';
import TextInput from '~/components/final_form/text_input';
import HiddenInput from '~/components/final_form/hidden_input';
import FormGrid from '~/components/@material-extend/form_grid';
import useFetch from '~/hooks/use_fetch';
import Select from '~/components/final_form/select';
import DevicesSelect from '~/components/devices_select';
import DatePicker from '~/components/final_form/date_picker';
import TimePicker from '~/components/final_form/time_picker';
import useMst from '~/hooks/use_mst';
import { PATHS } from '~/utils/constants';
import { IReport } from '~/mst/models/report';
import { ReportTypes } from '~/mst/models/report/types';
import useAuth from '~/hooks/use_auth';
import ParametersField from './parameters';

const standardOptions = [{ value: 'aqi', label: 'AQI' }];
const paramsOragnizationId = observable(
  {
    organization_id: null,
    setOrganizationId(value) {
      this.organization_id = value;
    }
  },
  {
    setOrganizationId: action
  }
);

function EditReportForm({ model }: { model: IReport }) {
  const { t } = useLocales();
  const { nodes, organizations } = useMst();
  const {
    values: {
      organization_id: organizationId,
      configuration: { from, working_hours: workingHours, time_zone: timeZone, type }
    }
  } = useFormState({ subscription: { values: true } });
  const { auth } = useAuth();

  const typeOptions = useMemo(
    () =>
      Object.values(ReportTypes).map((value) => ({
        value,
        label: t(`attributes.report.types.${value}`),
        ...(auth.user.isSupport === false && { disabled: value === ReportTypes.parameters_monitor || value === ReportTypes.perimeter_monitor })
      })),
    [auth.user?.isSupport, t]
  );

  const { change } = useForm();
  useEffect(() => paramsOragnizationId.setOrganizationId(organizationId), [organizationId]);

  const { isFetching } = useFetch(nodes, paramsOragnizationId);

  return (
    <Card>
      <CardContent>
        <FormGrid rowGap={3}>
          <HiddenInput name="configuration.time_zone" />
          <FormGrid columns="2">
            <TextInput name="name" label={t('attributes.report.name')} />
            <OrganizationSelect
              data-testid="reportOwnerSelect"
              disabled={!model.isNew}
              options={organizations.valueLabelPairsManagerAccess}
              name="organization_id"
              label={t('attributes.alert.organization_id')}
            />
          </FormGrid>
          <TextInput name="description" label={t('attributes.report.description')} />
          <FormGrid columns="2">
            <Select options={typeOptions} label={t('attributes.report.type')} name="configuration.type" />
            {[ReportTypes.aqi_basic, ReportTypes.aqi_extended].includes(type) && (
              <Select options={standardOptions} label={t('attributes.report.standard')} name="configuration.standard" />
            )}
          </FormGrid>
          <DevicesSelect
            allowSelectAll
            name="configuration.node_ids"
            loading={isFetching}
            label={t('attributes.report.node_ids')}
            options={nodes.monitorableValueLabelPairsSortedByStatus || []}
            multiple
          />
          {[ReportTypes.parameters_monitor, ReportTypes.perimeter_monitor].includes(type) && (
            <FieldArray name="configuration.parameters">{({ fields, meta }) => <ParametersField fields={fields} meta={meta} />}</FieldArray>
          )}
          <FormGrid columns="2" rowGap={1}>
            <DatePicker name="configuration.from" label={t('thiamis.download_csv.from')} timezone={timeZone} disableFuture />
            <DatePicker
              name="configuration.to"
              label={t('thiamis.download_csv.to')}
              timezone={timeZone}
              minDate={isString(from) && DateTime.fromISO(from)?.isValid ? DateTime.fromISO(from) : null}
              disableFuture
            />
            <Box>
              <Switch label={t('attributes.report.working_hours')} name="configuration.working_hours.enabled" />
            </Box>
            {workingHours?.enabled && (
              <FormGrid columns="2">
                <TimePicker name="configuration.working_hours.from" label={t('thiamis.download_csv.from')} />
                <TimePicker name="configuration.working_hours.to" label={t('thiamis.download_csv.to')} />
              </FormGrid>
            )}
          </FormGrid>
        </FormGrid>
      </CardContent>
      <OnChange name="configuration.node_ids">
        {async (value) => {
          const nodesIds = getOptionsForPayload(value);
          if (!isEmpty(nodesIds)) {
            await nodes.fetchDataPoints({ last: 1, path: [PATHS.TIMEZONE], node_id: nodesIds });
            const timezone = flow(
              map((id) => nodes.getById(id)?.data_points?.getByPath(PATHS.TIMEZONE)?.lastValue),
              compact,
              uniq,
              first
            )(nodesIds);
            if (timezone) {
              change('configuration.time_zone', timezone);
            }
          }
        }}
      </OnChange>
      <OnChange name="configuration.type">
        {(value) => {
          if ([ReportTypes.parameters_monitor, ReportTypes.perimeter_monitor].includes(value)) {
            change('configuration.parameters', [{ name: '', value: '' }]);
          }
        }}
      </OnChange>
    </Card>
  );
}

export default observer(EditReportForm);
