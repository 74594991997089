/* eslint-disable react/jsx-no-useless-fragment */
import {
  Checkbox,
  List,
  ListItem,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  ListItemSecondaryAction
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import type { IFilterGroup } from '~/mst/models/abstract/filterable/model';
import useLocales from '~/hooks/use_locales';

import { useState } from 'react';
import { BadgeFilterItemStyled, ListSubheaderStyled } from './styled';

const FilterItem = observer(({ sx, filter, onChange, groupKey }: { filter: any; groupKey: string; onChange: (filter: any) => void }) => {
  const { t } = useLocales();
  const labelId = `${groupKey}-${filter.title}`;
  return (
    <ListItem key={labelId} disablePadding>
      <ListItemButton
        selected={filter.chosen}
        role={undefined}
        dense
        sx={sx}
        onClick={() => onChange(filter)}
        disabled={filter.disabled && !filter.chosen}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            disabled={filter.disabled && !filter.chosen}
            checked={filter.chosen}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={t(`filters.filter-tags.${groupKey.toLowerCase()}.${filter.title.toLowerCase()}`, {
            defaultValue: filter.title
          })}
        />
        <ListItemSecondaryAction sx={{ right: '54px', top: '33%' }}>
          <BadgeFilterItemStyled showZero badgeContent={filter.count} color="primary" max={10000} overlap="rectangular" />
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  );
});

const ListFilterGroup = observer(({ group, onChange }: { group: IFilterGroup }) => {
  const { t } = useLocales();
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  if (isEmpty(group.filters)) {
    return null;
  }

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="div"
      subheader={
        <ListSubheaderStyled component="div" id={`nested-list-subheader-${group.title}`} sx={{ p: 0 }}>
          <ListItemButton onClick={handleClick} sx={{ px: 2 }}>
            <ListItemText primary={t(`filters.filter-groups.${group.title.toLowerCase()}`, { defaultValue: group.title })} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListSubheaderStyled>
      }
    >
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {group.filters.map((filter) => (
            <>
              <FilterItem filter={filter} groupKey={group.title} onChange={onChange} sx={{ pl: 4 }} />
              {Boolean(filter.children?.length) && (
                <List component="div" disablePadding>
                  {filter.children.map((child) => (
                    <FilterItem filter={child} groupKey={group.title} onChange={onChange} sx={{ pl: 6 }} />
                  ))}
                </List>
              )}
            </>
          ))}
        </List>
      </Collapse>
    </List>
  );
});

function Items({ groups, onChange }: { groups: IFilterGroup[]; onChange: (filter: any) => void }) {
  return (
    <List
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Filters
        </ListSubheader>
      }
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {groups.map((group) => (
        <ListFilterGroup key={group.title} group={group} onChange={onChange} />
      ))}
    </List>
  );
}

export default observer(Items);
