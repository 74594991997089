import React, { useEffect, useMemo } from 'react';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { Stack, IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useFormState } from 'react-final-form';
import { observable, action, computed } from 'mobx';
import groupBy from 'lodash/fp/groupBy';
import sortBy from 'lodash/fp/sortBy';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import useFetch from '~/hooks/use_fetch';
import DataPoints from '~/mst/models/data_points/node';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import compact from 'lodash/compact';

import { CirclePlusStyled, ButtonTextStyled, ButtonContainerStyled } from '~/pages/alert_edit/conditions_block/styled';
import Parameter from './parameter';

const paramsNodeIds = observable(
  {
    node_id: null,
    setNodeId(value) {
      this.node_id = value;
    }
  },
  {
    setNodeId: action
  }
);
function DataPointsField(props: FieldArrayRenderProps<any, HTMLInputElement>) {
  const { fields } = props;
  const {
    values: {
      configuration: { node_ids: nodeIds }
    }
  } = useFormState({ subscription: { values: true } });
  const dataPoints = useMemo(() => DataPoints.create({}), []);
  useEffect(() => paramsNodeIds.setNodeId(getOptionsForPayload(nodeIds)), [nodeIds]);

  useFetch(dataPoints, paramsNodeIds);

  const parameterOptions = useMemo(
    () =>
      computed(() => {
        return flow(
          compact,
          groupBy(({ label }) => label),
          map.convert({ cap: false })((values, name) => ({ models: values.map((value) => value.model), label: name, value: name })),
          sortBy('label')
        )(dataPoints.defaultValueLabelPairs);
      }),
    [dataPoints.defaultValueLabelPairs]
  ).get();

  return (
    <>
      {fields.map((name, index) => (
        <Parameter fields={fields} name={name} key={name} index={index} options={parameterOptions} isLoading={dataPoints.isFetching} />
      ))}
      <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
        <ButtonContainerStyled
          data-testid="addParameterBtn"
          onClick={() => {
            fields.push({ name: '', value: '' });
          }}
        >
          <IconButton>
            <CirclePlusStyled />
          </IconButton>
          <ButtonTextStyled>Add Parameter</ButtonTextStyled>
        </ButtonContainerStyled>
      </Stack>
    </>
  );
}

export default observer(DataPointsField);
