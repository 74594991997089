import { types as t, Instance } from 'mobx-state-tree';
import { ReportTypes } from './types';

const ReportConfigurationModel = t.model({
  node_ids: t.array(t.string),
  subscribers: t.array(t.string),
  parameters: t.array(t.frozen()),
  standard: t.maybeNull(t.enumeration('Standard', ['aqi'])),
  type: t.optional(t.enumeration('Type', Object.values(ReportTypes)), ReportTypes.aqi_basic),
  from: t.maybeNull(t.union(t.string, t.number)),
  to: t.maybeNull(t.union(t.string, t.number)),
  time_zone: t.maybeNull(t.string),
  working_hours: t.maybeNull(t.frozen())
});

export interface IReportConfigurationModel extends Instance<typeof ReportConfigurationModel> {}

export default ReportConfigurationModel;
