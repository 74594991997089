import { useState, useMemo } from 'react';
import get from 'lodash/get';

import { Tooltip, Box } from '@mui/material';
import { useFormState } from 'react-final-form';
import type { IAlert } from '~/mst/models/alert';
import I18n from '~/utils/i18n';
import { PATHS } from '~/utils/constants';
import { EXPRESSIONS } from '~/mst/models/condition/types';
import SelectStyled from './styled';

type ConditionFieldType = {
  name: string;
  model: IAlert;
};

const FuncOptions = EXPRESSIONS.map((exp) => ({ value: exp, label: I18n.t(`conditions.expressions.${exp}`) }));

function ComparatorSelect(props: ConditionFieldType) {
  const { name, model } = props;
  const { values } = useFormState({ subscription: { values: true } });
  const [open, setOpen] = useState(false);
  const { expr, dataPointId, deviceId } = get(values, name);

  const isStatus = model?.nodes?.getById(deviceId)?.data_points?.getById(dataPointId)?.shortPath === PATHS.ONLINE;
  const options = useMemo(() => (isStatus ? FuncOptions.filter(({ value }) => ['eq', 'neq'].includes(value)) : FuncOptions), [isStatus]);
  return (
    <Tooltip title={I18n.t(expr ? `conditions.expressions.${expr}_title` : 'attributes.alert_rule.func')} placement="top" open={open} arrow>
      <Box onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        <SelectStyled
          label="Operator"
          name={`${name}.expr`}
          data-testid={`${name}.exprSelect`}
          options={options}
          onClose={() => setOpen(false)}
          noHelperText
        />
      </Box>
    </Tooltip>
  );
}

export default ComparatorSelect;
