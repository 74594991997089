/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef } from 'react';
import { SortingState, RowDetailState, IntegratedSorting, SelectionState, IntegratedSelection } from '@devexpress/dx-react-grid';
import { Grid, VirtualTable, TableHeaderRow, TableRowDetail, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { observer } from 'mobx-react-lite';

import { STATUS_WEIGHTS } from '~/utils/constants';
import useSelection from '~/hooks/react_grid/use_selection';
import useLoading from '~/hooks/react_grid/use_loading';
import useSorting from '~/hooks/react_grid/use_sorting';
import useExport from '~/hooks/react_grid/use_export';
import useSubscribable from '~/hooks/use_subscribable_mst';
import useMst from '~/hooks/use_mst';

import I18n from '~/utils/i18n';
import { GridStyled } from '~/components/react_grid/styled';
import { Container } from '~/components/react_grid';
import useExpandRow from '~/hooks/react_grid/use_expand_row';

import NoDevicesScreen from '../no_devices';
import GridDetailContainer from './detail_row';
import useColumnExtensions from './use_column_extensions';
import { NameProvider, SharedLeasedProvider, StatusTypeProvider, ActionsProvider, OrganizationNameProvider } from './info_providers';

const compareStatuses = (a, b) => {
  const statusA = STATUS_WEIGHTS[a];
  const statusB = STATUS_WEIGHTS[b];

  return statusA - statusB;
};

export const columns = [
  { name: 'name', title: I18n.t('attributes.node.name') },
  { name: 'sharedTo', title: ' ', sortingEnabled: false },
  { name: 'description', title: I18n.t('attributes.node.description') },
  { name: 'organizationName', title: I18n.t('attributes.thiamis.organization_id') },
  { name: 'status', title: I18n.t('attributes.node.status') },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

// FIXME: This is a workaround for some reason the sharedTo column is being displayed on the table if cloning array
export const exportColumns = [
  { name: 'name', title: I18n.t('attributes.node.name') },
  { name: 'sharedTo', title: ' ', sortingEnabled: false },
  { name: 'description', title: I18n.t('attributes.node.description') },
  { name: 'organizationName', title: I18n.t('attributes.thiamis.organization_id') },
  { name: 'status', title: I18n.t('attributes.node.status') },
  { name: 'actions', title: ' ', sortingEnabled: false }
];
exportColumns.splice(2, 0, { name: 'leasedTo', title: I18n.t('attributes.node.leased_to') }).pop();
exportColumns[1].title = I18n.t('attributes.node.shared_to');

const getRowId = ({ _id }) => _id;
const customizeCell = (cell, row, column) => {
  if (column.name === 'name') {
    cell.value += `\n${row.serial}`;
  }
  if (column.name === 'organizationName') {
    cell.value += `\n${row.organizationExternalId}`;
  }
  if (column.name === 'sharedTo') {
    cell.value = row.sharedTo.map(({ name, externalId }) => `${name}\n${externalId}`).join('\n');
  }
  if (column.name === 'leasedTo') {
    cell.value = row.leasedTo.map(({ name, externalId }) => `${name}\n${externalId}`).join('\n');
  }
};

function ToogleCellComponent(data) {
  const {
    tableRow: {
      row: { isExpandable }
    }
  } = data;
  return isExpandable ? <TableRowDetail.ToggleCell {...data} /> : <VirtualTable.StubCell {...data} />;
}

function GridContainer() {
  const { selection, setSelection } = useSelection();
  const columnExtensions = useColumnExtensions();
  const { expandedRowIds, handleSetExpandedRowIds, Row } = useExpandRow();

  const { sorting, handleSortingChange } = useSorting([
    { columnName: 'status', direction: 'desc' },
    { columnName: 'name', direction: 'asc' }
  ]);
  const { nodes } = useMst();
  const { subscribe, unsubscribe } = useSubscribable((dataPoints) => {
    if (dataPoints) {
      dataPoints.forEach((dataPoint) => {
        const { _id: dataPointId, measurements, node_id: nodeId } = dataPoint;
        nodes.getById(nodeId)?.getDataPointById(dataPointId)?.updateMeasurements(measurements);
      });
    }
  });

  useEffect(() => {
    subscribe(expandedRowIds);
    return () => unsubscribe();
  }, [expandedRowIds, subscribe, unsubscribe]);

  const { messages, RootComponent } = useLoading(nodes);

  const rows = nodes.filteredList;

  const exporterRef = useRef(null);
  const { isExporting, stopExport } = useExport();

  useEffect(() => {
    if (isExporting) {
      stopExport();
      exporterRef.current.exportGrid();
    }
  }, [isExporting, exporterRef, stopExport]);

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'devices.xlsx');
    });
  };

  return (
    <GridStyled>
      <Grid rows={rows} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting columnExtensions={[{ columnName: 'status', compare: compareStatuses }]} />
        <SelectionState selection={selection} onSelectionChange={setSelection} />
        <IntegratedSelection />
        <NameProvider for={['name']} />
        <OrganizationNameProvider for={['organizationName']} />
        <SharedLeasedProvider for={['sharedTo']} />
        <StatusTypeProvider for={['status']} />
        <ActionsProvider for={['actions']} />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={handleSetExpandedRowIds} />
        <VirtualTable
          height="auto"
          containerComponent={Container}
          messages={messages}
          columnExtensions={columnExtensions}
          noDataRowComponent={NoDevicesScreen}
        />
        <TableSelection showSelectAll highlightRow rowComponent={Row} />
        <TableRowDetail rowComponent={GridDetailContainer} toggleCellComponent={ToogleCellComponent} />
        <TableHeaderRow showSortingControls />
      </Grid>
      <GridExporter
        sorting={sorting}
        ref={exporterRef}
        rows={rows}
        columns={exportColumns}
        customizeCell={customizeCell}
        onSave={onSave}
        selection={selection}
      />
    </GridStyled>
  );
}

export default observer(GridContainer);
