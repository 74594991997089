/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import LoadingBox from '~/components/loading_box';
import useNavigate from '~/hooks/use_navigate';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import useLocales from '~/hooks/use_locales';
import Breadcrumbs from '~/components/breadcrumbs';
import NavigateDropdown from '~/components/navigate_dropdown/devices_dropdown';
import ActionsButton from '~/pages/devices/form_top/actions_button';
import { SelectionProvider } from '~/hooks/react_grid/use_selection';
import useDevicesSubscribe from '~/hooks/use_devices_subscribe';
import Container from '~/components/container';
import useAuth from '~/hooks/use_auth';

import { PATHS } from '~/utils/constants';
import DeviceLayout from './layouts';

function DeviceEdit() {
  const { t } = useLocales();
  const { id } = useParams();
  const { nodes } = useMst();
  const { auth } = useAuth();
  const { navigate } = useNavigate();

  const { isFetching, isFetched } = useFetch(nodes, { includes: ['profiles'] });

  const node = nodes.getById(id);

  useEffect(() => {
    if ((isFetched && node == null) || (node && !node.isModifiable)) {
      navigate('devices');
    }
  }, [id, isFetched, node, navigate]);

  useDevicesSubscribe([id]);

  const isAdmin = auth.hasAdminDevice(node?.organization_id);

  const links = useMemo(
    () => [
      { title: t('devices_page.title'), to: 'devices' },
      [
        { title: t('thiamis.monitor_page_breadcrumb'), to: `devices/${id}/monitor`, hasAccess: node?.isMonitorable },
        { title: t('thiamis.config_page_breadcrumb'), to: `devices/${id}/edit`, hasAccess: node?.isModifiable },
        { title: t('thiamis.admin_page_breadcrumb'), to: `devices/${id}/admin`, hasAccess: isAdmin }
      ]
    ],
    [id, t, isAdmin, node?.isModifiable, node?.isMonitorable]
  );

  if (node?.isCreatable) {
    return navigate(`devices/${id}/create`);
  }

  return (
    <Container>
      <Breadcrumbs links={links} sx={{ marginTop: '-14px' }} />
      <Stack direction="column" spacing={{ xs: 0, sm: 2, md: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <NavigateDropdown
            onOpen={() => {
              nodes.fetchDataPoints({ last: 1, path: [PATHS.AQ, PATHS.ONLINE], organization_id: auth.organizationId });
            }}
            onChange={(value) => navigate(`devices/${value}/edit`)}
            options={nodes.modifiableValueLabelPairsSortedByStatus}
            loading={isFetching}
            value={id}
            model={node}
          />
          <Box display="flex" justifyContent="flex-end" alignItems="center" alignContent="center">
            <SelectionProvider selected={[id]}>
              <ActionsButton canManage />
            </SelectionProvider>
          </Box>
        </Stack>
        {isFetched && node ? <DeviceLayout node={node} /> : <LoadingBox />}
      </Stack>
    </Container>
  );
}

export default observer(DeviceEdit);
