import React from 'react';
import { matchPath } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

import {
  DashboardIcon,
  MapIcon,
  UsersIcon,
  DevicesIcon,
  CertificatesIcon,
  AlertIcon,
  OrganizationsIcon,
  ApiIcon,
  FilesIcon,
  DeviceHubIcon,
  PartnershipsIcon,
  AnalyticsIcon
} from '~/assets';
import Mail from '@mui/icons-material/Mail';
import ScienceIcon from '@mui/icons-material/Science';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import Label from '~/components/label';
import { Icon } from '~/components/iconify';
import AccessContextModal from './access_context_switch';

type SidebarConfig = {
  canReadTokens: boolean;
  canReadDevices: boolean;
  canReadReports: boolean;
  canReadUsers: boolean;
  canAdmin: boolean;
  notificationsCount: number;
};

const getSidebarConfig = ({ notificationsCount, canReadTokens, canReadDevices, canReadUsers, canReadReports, canAdmin }: SidebarConfig) => [
  {
    items: [
      { title: 'home', path: '/home', icon: Icon(HomeIcon) },
      {
        title: 'dashboards',
        path: '/dashboards',
        icon: Icon(DashboardIcon),
        access: canReadReports
      }
    ]
  },
  {
    subheader: 'monitoring',
    items: [
      { title: 'devices', path: '/devices', icon: Icon(DevicesIcon), access: canReadDevices },
      { title: 'alerts', path: '/alerts', icon: Icon(AlertIcon), access: canReadDevices },
      { title: 'map', path: '/map', icon: Icon(MapIcon), access: canReadDevices },
      { title: 'certificates', path: '/certificates', icon: Icon(CertificatesIcon), access: canAdmin }
    ]
  },
  {
    subheader: 'analytics',
    items: [
      {
        title: 'reports',
        info: <sup>beta</sup>,
        path: '/reports',
        icon: Icon(AnalyticsIcon),
        access: canReadDevices
      }
    ]
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'users',
        path: '/users',
        access: canReadUsers,
        icon: Icon(UsersIcon)
      },
      {
        title: 'user_invitations',
        path: '/invitations',
        access: canReadUsers,
        icon: Icon(Mail),
        info: (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {notificationsCount > 0 && (
              <Label variant="outlined" color="primary">
                {notificationsCount}
              </Label>
            )}
          </>
        )
      },
      { title: 'organizations', path: '/organizations', icon: Icon(OrganizationsIcon) },
      { title: 'partnerships', path: '/partnerships', icon: Icon(PartnershipsIcon) }
    ]
  },
  {
    subheader: 'general',
    items: [
      { title: 'api_api', path: '/api', icon: Icon(ApiIcon), access: canReadTokens },
      { title: 'webhooks', path: '/webhooks', icon: Icon(DeviceHubIcon), access: canReadTokens },
      { title: 'archives', path: '/archives', icon: Icon(FilesIcon), access: canReadDevices }
    ]
  },
  {
    subheader: 'admin',
    items: [
      { title: 'calibrations', path: '/calibrations', icon: Icon(ScienceIcon), access: canAdmin },
      {
        title: 'firmwares',
        path: 'https://p.datadoghq.com/sb/3301c1d90-4a819ba0eb436a2402d913c1555f12d1',
        icon: Icon(DeveloperBoardIcon),
        target: '_blank',
        access: canAdmin
      },
      { title: 'access_context', component: <AccessContextModal />, access: canAdmin }
    ]
  }
];

export default getSidebarConfig;

export function getActive(slug: string, path: string, pathname: string) {
  const currentPath = pathname ? pathname.replace(`/${slug}`, '') : '';
  return path ? !!matchPath({ path, end: false }, currentPath) : false;
}
