import { useEffect } from 'react';
import type { IDashboard } from '~/mst/models/dashboard';
import useSubscribable from '~/hooks/use_subscribable_mst';
import { WidgetTypes } from '~/mst/models/widget/types';

const updatesOffWidgets = [WidgetTypes.ChartTableWidget, WidgetTypes.ChartWidget];
const useDashboardSubscribe = (dashboard: IDashboard) => {
  function setMeasurements(dataPoints = []) {
    dataPoints.forEach((dataPoint) => {
      const { _id: dataPointId, measurements } = dataPoint;
      dashboard?.widgets.toArray
        .filter((widget) => {
          if (dashboard.is_updates_on === false && updatesOffWidgets.includes(widget.type)) {
            return false;
          }
          return true;
        })
        .forEach((widget) => widget.nodes?.getNodeByDataPointId(dataPointId)?.data_points?.getById(dataPointId)?.updateMeasurements(measurements));
    });
  }

  const { subscribe, unsubscribe } = useSubscribable(setMeasurements);

  useEffect(() => {
    subscribe(dashboard?.nodeIds);
    return () => unsubscribe();
  }, [dashboard?.nodeIds, subscribe, unsubscribe]);
};

export default useDashboardSubscribe;
