import React from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import isNil from 'lodash/isNil';
import { PERMISSIONS } from '~/mst/models/permissions/types';
import useAuth from '~/hooks/use_auth';

type ProtectedRouteProps = {
  permission?: PERMISSIONS;
  children: React.ReactElement;
};

function ProtectedRoute(props: ProtectedRouteProps) {
  const { permission, children } = props;
  const { auth } = useAuth();

  if (isNil(permission) || auth.user?.hasPermission(permission)) {
    return children;
  }
  return <Navigate to="/" />;
}

export default observer(ProtectedRoute);
