import { EnumAsUnion } from '~/utils/enum_as_union';

export enum ReportTypes {
  aqi_basic = 'aqi_basic',
  aqi_extended = 'aqi_extended',
  parameters_monitor = 'parameters_monitor',
  perimeter_monitor = 'perimeter_monitor'
}

export type ReportTypesUnionType = EnumAsUnion<typeof ReportTypes>;
export type ReportTypesRecordType = Record<ReportTypesUnionType, string[]>;
