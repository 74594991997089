import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import { Grid, VirtualTable, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';

import { GridStyled } from '~/components/react_grid/styled';
import { Container, Row } from '~/components/react_grid';
import useLoading from '~/hooks/react_grid/use_loading';
import useSorting from '~/hooks/react_grid/use_sorting';
import useMst from '~/hooks/use_mst';
import I18n from '~/utils/i18n';

import { CreatedAtProvider, ActionsProvider, NameProvider, DatetimeProvider } from './info_providers';

function compareDates(a: any, b: any) {
  // @ts-ignore
  return (b != null) - (a != null) || b - a;
}

const columns = [
  { name: 'name', title: 'Name' },
  { name: 'type', title: 'Type', getCellValue: (row) => (row.type ? I18n.t(`attributes.report.types.${row.type}`) : null) },
  { name: 'from', title: 'From', format: DateTime.DATE_SHORT },
  { name: 'to', title: 'To', format: DateTime.DATE_SHORT },
  { name: 'created_at', title: 'Date Created' },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

const getRowId = (row) => row.id;

function GridContainer() {
  const { reports } = useMst();

  const { messages, RootComponent } = useLoading(reports);
  const { sorting, handleSortingChange } = useSorting([{ columnName: 'created_at', direction: 'asc' }]);

  return (
    <GridStyled>
      <Grid rows={reports.filteredList} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting
          columnExtensions={[
            { columnName: 'created_at', compare: compareDates },
            { columnName: 'from', compare: compareDates },
            { columnName: 'to', compare: compareDates }
          ]}
        />
        <CreatedAtProvider for={['created_at']} />
        <DatetimeProvider for={['from', 'to']} />
        <ActionsProvider for={['actions']} />
        <NameProvider for={['name']} />
        <VirtualTable
          rowComponent={Row}
          messages={messages}
          columnExtensions={[{ columnName: 'actions', width: 65 }]}
          height="auto"
          containerComponent={Container}
        />
        <TableHeaderRow showSortingControls />
      </Grid>
    </GridStyled>
  );
}

export default observer(GridContainer);
